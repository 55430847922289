import { useContext, useEffect } from 'react'
import { SubmitHandler, useFormContext, useWatch } from 'react-hook-form'
import { useIntl } from 'react-intl'

import { Box, Divider, Grid, Typography } from '@mui/material'
import { Button, ControlledSelect, EditButton, InfoBar, RequiredSign } from '@rent/ui'
import { ReactComponent as LockIcon } from 'assets/icons/Lock.svg'
import { ReactComponent as PenIcon } from 'assets/icons/Pen.svg'
import { ReactComponent as ProtectionIcon } from 'assets/icons/Protection.svg'
import { FormFieldGenerator } from 'components/shared'
import { OrderContext } from 'context'
import dayjs from 'dayjs'
import { calcProductsTotalDeposit } from 'helpers'
import { useFormFieldErrorFocus } from 'hooks'
import { ContractGenerationDataFields, CustomerMaskedData, IMappedProduct } from 'models'

import { ContractGenerationValidationSchema, contractGenerationDataFieldsProperties } from '../consts'
import { useWeBookExtensionData } from '../hooks'
import DataConsents from './DataConsents'
import PaymentBox from './PaymentBox'
import { formBoxStyle } from './styles'

interface FormDataProps {
  onSubmitForm: SubmitHandler<ContractGenerationValidationSchema>
  representantNamesOptions: { value: string }[]
  disabled: boolean
  onEditData: () => void
  externalId: string
  orderProductsData: IMappedProduct[]
  buyerName?: string
  customerMaskedData?: CustomerMaskedData | null
}

const FormData = ({
  representantNamesOptions,
  onSubmitForm,
  disabled,
  onEditData,
  orderProductsData,
  buyerName,
  customerMaskedData,
}: FormDataProps) => {
  const { formatMessage } = useIntl()
  const { contextData } = useContext(OrderContext)
  const {
    control,
    setValue,
    formState: { dirtyFields, errors },
  } = useFormContext()

  useFormFieldErrorFocus()

  const watchedIdentityCardValidFrom = useWatch({ control, name: ContractGenerationDataFields.identityCardValidFrom })
  const watchedIdentityCardValidTo = useWatch({ control, name: ContractGenerationDataFields.identityCardValidTo })
  const linkExtensionUsed = useWatch({ control, name: 'linkExtensionUsed' })
  const editableFields = useWatch({ control, name: 'editableFields' })

  const { onEditRepresentantDataClick, onEditFieldClick } = useWeBookExtensionData({
    buyerName,
    representantNamesOptions,
    customerMaskedData,
  })

  // update identityCardValidTo once identityCardValidFrom is provided by adding 10 years
  useEffect(() => {
    if (
      !linkExtensionUsed &&
      dayjs(watchedIdentityCardValidFrom).isValid() &&
      dayjs(watchedIdentityCardValidFrom) > dayjs(dayjs().subtract(11, 'year')) &&
      !errors?.[ContractGenerationDataFields.identityCardValidTo] &&
      dirtyFields[ContractGenerationDataFields.identityCardValidFrom] &&
      (watchedIdentityCardValidTo === null || watchedIdentityCardValidTo === undefined || watchedIdentityCardValidTo === '')
    ) {
      setValue(ContractGenerationDataFields.identityCardValidTo, dayjs(watchedIdentityCardValidFrom).add(10, 'year'))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerMaskedData, dirtyFields, watchedIdentityCardValidTo, watchedIdentityCardValidFrom, errors])

  return (
    <Box mb={2.4}>
      <Box mb={{ xs: 3.2, md: 4.6 }}>
        <Box sx={formBoxStyle}>
          {/* title */}
          <Box color="text.secondary" display="flex" alignItems="center">
            <LockIcon fill="inherit" />
            <Typography variant="caption" ml={0.8} sx={{ whiteSpace: 'pre-line' }}>
              {formatMessage({ id: 'message.your_data_are_protected_with_SSL' })}
            </Typography>
          </Box>

          <Divider sx={{ my: 2.4 }} />

          <Box mb={2.5}>
            <ControlledSelect
              fieldName={ContractGenerationDataFields.nameAndLastName}
              inputProps={{
                name: ContractGenerationDataFields.nameAndLastName,
                fullWidth: true,
              }}
              label={formatMessage({ id: 'form.representative_label' })}
              placeholder={formatMessage({ id: 'form.representative_placeholder' })}
              control={control}
              disabled={disabled || (customerMaskedData && linkExtensionUsed)}
              options={representantNamesOptions}
            />
          </Box>

          {/* form fields */}
          <Grid container spacing={2.4}>
            <Grid item xs={12} sm={6}>
              <FormFieldGenerator
                fieldName={ContractGenerationDataFields.pesel}
                properties={{
                  ...contractGenerationDataFieldsProperties.pesel,
                  disabled: disabled || (customerMaskedData?.[ContractGenerationDataFields.pesel] && linkExtensionUsed),
                }}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <FormFieldGenerator
                fieldName={ContractGenerationDataFields.identityCardNumber}
                properties={{
                  ...contractGenerationDataFieldsProperties.identityCardNumber,
                  disabled: disabled,
                  helperIconLabelText: customerMaskedData ? formatMessage({ id: 'message.identityCardNumber_required' }) : '',
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormFieldGenerator
                fieldName={ContractGenerationDataFields.identityCardValidFrom}
                properties={{
                  ...contractGenerationDataFieldsProperties.identityCardValidFrom,
                  disabled: disabled || (customerMaskedData?.[ContractGenerationDataFields.identityCardValidFrom] && linkExtensionUsed),
                  editableInitValue:
                    watchedIdentityCardValidFrom === customerMaskedData?.identityCardValidFrom
                      ? customerMaskedData?.identityCardValidFrom
                      : '',
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormFieldGenerator
                fieldName={ContractGenerationDataFields.identityCardValidTo}
                properties={{
                  ...contractGenerationDataFieldsProperties.identityCardValidTo,
                  disabled: disabled || (customerMaskedData?.[ContractGenerationDataFields.identityCardValidTo] && linkExtensionUsed),
                  editableInitValue:
                    watchedIdentityCardValidTo === customerMaskedData?.identityCardValidTo ? customerMaskedData?.identityCardValidTo : '',
                }}
              />
            </Grid>

            {!disabled && customerMaskedData && (
              <Grid item>
                <EditButton
                  isEditClicked={!linkExtensionUsed}
                  onClick={onEditRepresentantDataClick}
                  editText="button.edit_representative_data"
                  cancelText="button.restore"
                />
              </Grid>
            )}

            <Grid item xs={12}>
              <InfoBar
                title={
                  <>
                    <Typography variant="subtitle2" color="textPrimary" fontWeight={700}>
                      <RequiredSign sx={{ mr: 0.2 }} />
                      {formatMessage({ id: 'message.your_data_is_protected' })}
                      <Typography variant="subtitle2" ml={0.5} component="span">
                        {formatMessage({ id: 'message.why_we_need_sensitive_data' })}
                      </Typography>
                    </Typography>
                  </>
                }
                icon={ProtectionIcon}
                align="left"
                iconSize={1.8}
                sx={{ mb: 2.4, mt: -0.4 }}
              />

              <FormFieldGenerator
                fieldName={ContractGenerationDataFields.returnAccountNumber}
                properties={{
                  type: 'iban',
                  inputProps: { fullWidth: true },
                  disabled,
                  editableInitValue: customerMaskedData?.returnAccountNumber ?? '',
                  isNotEditable: editableFields[ContractGenerationDataFields.returnAccountNumber],
                  onEditClick: onEditFieldClick,
                }}
                onSubmitForm={onSubmitForm}
              />
            </Grid>
          </Grid>

          {/* Chwilowo zakomentowane decyzją zarządu. Czekamy na decyzję czy chcemy mieć wymiany w tym procesie czy tylko w koszykach wirtualnych. */}
          {/* <Divider sx={{ my: 3 }} />
          <Box mb={0.8}>
            <AgreementReplacement disabled={disabled} externalId={externalId} />
          </Box> */}

          {disabled && (
            <Button
              variant="text"
              size="small"
              startIcon={<PenIcon />}
              sx={{ mt: 2.4 }}
              onClick={onEditData}
              id="editAgreementFormData-button"
            >
              {formatMessage({ id: 'button.edit_data' })}
            </Button>
          )}
        </Box>

        <Box sx={formBoxStyle}>
          <Box mb={2}>
            <InfoBar
              title={formatMessage({
                id: contextData.isLoan ? 'message.phone_needed_to_sign_contract_loan' : 'message.phone_needed_to_sign_contract',
              })}
              align="left"
              iconSize={1.6}
            />
          </Box>
          <FormFieldGenerator
            fieldName={ContractGenerationDataFields.buyerPhone}
            properties={{
              type: 'phone',
              inputProps: {
                fullWidth: true,
                sx: { maxWidth: { xs: '100%', sm: '50%' } },
              },
            }}
            onSubmitForm={onSubmitForm}
          />
        </Box>
        {!contextData.isExtension && <PaymentBox deposit={calcProductsTotalDeposit(orderProductsData)} disabled={disabled} />}
      </Box>

      {/* consents */}
      {!disabled && <DataConsents />}
    </Box>
  )
}

export default FormData
