const general = {
  product_selection: 'Wybór produktu',
  personal_data: 'Dane osobowe',
  contract_generation: 'Generowanie umowy',
  finalization: 'Finalizacja',
  safe_purchase: 'Bezpieczne zakupy',
  summary: 'Podsumowanie',
  insurance_basic: 'Ochrona podstawowa SafeUp',
  insurance_basic_subtitle: 'Gwarancja bezpieczeństwa użytkowania subskrybowanego produktu.',
  insurance_extended: 'Ochrona rozszerzona SafeUp+',
  insurance_extended_subtitle: 'Rozszerzony zakres ochrony produktu.',
  order_summary: 'Podsumowanie zamówienia',
  in_price: 'W cenie',
  pcs: 'szt.',
  offer_provider: 'Ofertę zapewnia',
  your_offer: 'Twoja oferta',
  your_offer_loan: 'Twoja oferta pożyczki',
  products: 'Produkty',
  rental_period: 'Okres najmu',
  subscription_period: 'Okres subskrypcji',
  loan_period: 'Okres spłaty pożyczki',
  commission: 'Prowizja',
  commissionIncluded: 'Zawarta w kwocie pożyczki',
  deposit: 'Kaucja zwrotna',
  net: 'netto',
  PLN: 'zł',
  mth: 'msc',
  months: 'miesięcy',
  price: 'Cena',
  installment: 'Rata',
  month_rental_price: 'Miesięczna cena wynajmu',
  month_subscription_price: 'Miesięczna cena subskrypcji',
  month_loan_price: 'Miesięczna rata',
  final_loan_price: 'Ostateczna kwota przelewu',
  amount: 'Ilość',
  final_price: 'Finalna  cena',
  need_help: 'Potrzebujesz pomocy?',
  rental_offer: 'Oferta najmu',
  subscription_offer: 'Oferta subskrypcji',
  loan_offer: 'Oferta pożyczki',
  rodo_info: 'klauzuli informacyjnej',
  statute_rentPay: 'regulaminu RentPay',
  do_you_have_a_promo_code: 'Masz kod promocyjny?',
  agreement: 'Umowa',
  terms_of_service: 'Regulamin',
  privacy_policy: 'Polityka prywatności',
  wesub: 'WeSub',
  copyright: 'Copyright © WeSub Spółka Akcyjna. {year} All Rights Reserved.',
  offer_preview: 'Podgląd oferty',
  additional_services: 'Opcje dodatkowe',
  selected: '(Wybrano {count, plural, =0 {# usług} one {# usługę} few {# usługi} many {# usług} other {# usługi}})',
  services_count: '({count, plural, =0 {# usług} one {# usługa} few {# usługi} many {# usług} other {# usług}})',
}

export { general }
