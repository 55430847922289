import { useIntl } from 'react-intl'

import { Box, Typography, useMediaQuery, useTheme } from '@mui/material'
import { BasicDialog } from '@rent/ui'

import { containerStyle, contentStyle } from './styles'

interface SafeUpChangeConfirmationProps {
  handleSetSafeUpPlus: (safeupPlusTaken: boolean) => void
  setOpenDialog: (value: React.SetStateAction<boolean>) => void
}
export const SafeUpChangeConfirmation = ({ handleSetSafeUpPlus, setOpenDialog }: SafeUpChangeConfirmationProps) => {
  const { formatMessage } = useIntl()
  const { breakpoints } = useTheme()
  const matchesMobile = useMediaQuery(breakpoints.down('sm'))

  return (
    <BasicDialog
      open
      maxWidth="md"
      fullWidth
      title={
        <Typography variant="inherit" mb={-1} sx={{ mx: -1 }} color="#0c256d">
          {formatMessage({ id: 'safeup.confirmCancel' })}
        </Typography>
      }
      onClose={() => setOpenDialog(false)}
      actions={[
        {
          label: formatMessage({ id: 'safeup.cancelSafeUpPlus' }),
          onClick: () => {
            handleSetSafeUpPlus(false)
            setOpenDialog(false)
          },
          variant: 'outlined',
          fullWidth: matchesMobile,
          id: 'cancelSafeUpPlus-button',
          actionStyle: { mt: { xs: 0, sm: -2 } },
        },
        {
          label: formatMessage({ id: 'safeup.keepSafeUpPlus' }),
          onClick: () => {
            setOpenDialog(false)
          },
          fullWidth: matchesMobile,
          id: 'dontCancelSafeUpPlus-button',
          actionStyle: { mt: { xs: 0, sm: -2 } },
        },
      ]}
    >
      <Box>
        <Typography variant="h5" mb={2}>
          {formatMessage({ id: 'safeup.safeUpDescription' })}
        </Typography>
        <Typography>
          {formatMessage(
            { id: 'safeup.cancelWarning' },
            {
              strong: chunks => <strong>{chunks}</strong>,
            },
          )}
        </Typography>
        <Box sx={containerStyle}>
          <Typography variant="h5" fontWeight={700} color="#0c256d" mb={3}>
            {formatMessage({ id: 'safeup.safeUpPlusBenefits' })}
          </Typography>
          <Box sx={contentStyle}>
            <Box>
              <Typography variant="subtitle1" color="primary" mb={1}>
                {formatMessage({ id: 'safeup.contractDuration' })}
              </Typography>
              <img
                src="data:image/jpeg;base64,iVBORw0KGgoAAAANSUhEUgAAACoAAAAsCAYAAAATmipGAAABVGlDQ1BJQ0MgUHJvZmlsZQAAGJV1kD9Iw1AQxr/UaqE6dLCbQwQF/0SRqotbrVgLHUKt+AcE0zSmQhKfSURcnJ0ddNHVVbeKk4MgzooFcXB1E4QsWuK9Rk2reI/jfnx897g7IAKFMSMKwLRcu5CdFpeWV8TYCwQkEac3rKgOS8tyniz4rq3hPZCb4m6E/9V7mzg7qe0NPRUvjw6ekx1//S0RL2uOSvWDckBltgsIfcTyjss4U6LbpqGI9znrAR9zLgV83vAUCxnia+KEWlHKxPfEUqlJ15vYNLbVrxn49F2atTDPK2UP8shCxCzmMPOPb6Lhy2ATDLuwsQEdFbjUlSaFwYBGnIMFFaOQiFMYo5zk9/19t1Dbon2mckDEDLU1Gbh45WuFWr9Eo9wAV6tMsZWfawpe1FkfTwXcWQXaD33/bRGIDQL1mu+/V32/fgq0PVKv9wlk+GFaLRDQ9QAAADhlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAAqACAAQAAAABAAAAKqADAAQAAAABAAAALAAAAAB4knKRAAAD00lEQVRYCe2Y22tUVxSHv21ibsbEpFHiLSrRBk29hAjVeqlQEKsiiOCDpYVCURAKKg19qP9DBR98UBClVKkoWBGEQKEoNkISQ1pUYm4aE8nFlphoTBOzu9ZM4pnTmcmcM4mTCWTBmcvZa+/9nbUvv72O6Ruwlmlgs6YBYwBxBnSyRyo1YoNDb0GvqbTMNDAOQDjo4064cR9b2wqDw45ngn+ZC4chS2BHzQ36oAN7/Ceoahgrn7rvYfeIOotpZAR79jeobpo6uHF6dkD7B+FhO/zvScapm9AiB/TvV/D634R27qczB1QFKolFygH183hT4DsDOtlBnzYRdW/4XsOQLtW2lcKHhXBXxKGuJXrNovmwfTW8GYKrVXEvWP+gqsFHd2KO74K5GQGZtd+cg1+r3bCq0x8VYU59CRtXBMrs7vVwRHyH/Euz/6EvzIPNK4OQ2n1aCuaLTwIgro8UaXrDsneQWmYObISlH7jcvP7xDzooQxgqDCMW+7w3vD/NG/rfuH213kB8ouIftPsl/HxXzgQyL9/K+eBmPZypDAeVMqoaxfcP6BPgnj5sxSXoivBQ4bXD7ph3OVNLN/arM1D/NMwp7MbsFMjNgozZwaj1DkReJEYmana6TJPMYHl3n+ezhGk7DTlSb9T8LyatqIdqiVBMU0nWaOo1QfM/9BPsMN7qM6DxRi5aPe8RXb8Cc/lbqNgL+XOjtRf7fulS+GE/7C2XxejkRLEqel9M3+2BXeswn5Vi18lGfvIXeNIdq313+cEtmB8PgUiwbeiE9hdwv9XtE+Wfd9DKP2FHSWDLMPvKYFMx9vwduCJ76j+SHWjGqmmM7p9qadJ0qmxjKrPyYObE545K6a7R3CX1Xgd9PXx6B734O1ZUxZwQjV9VCAtyMN/vlqkgAPVt2EcdoArVI4KgsKVLxCcXU74cCrIdFEl57M06OHULWgXWo3kH1Qav3cM2d2L2i2ZvleiuFRiN2gY5fMg1rvX0i1I1YStFya7XSDTlvw/zB6qRqmnGPpBstWQhlC2H1QK7ZjGmWI5zi+Y5XQ+Lb2MXtkWiVtsKf7UFIs+zHsfHxy9/oGMND0hqXTfa+RyRyOwMrL7VSBdJHTNVJT2E6PVS5qKeRyeQPMYHOgaji6dXIPR6z+Z9H33PILGanwGNFSG/5ckZUT2/hrwb1YdKTtDy4uD+HBL25AOVrc4c2xnMHkJAne1Js8Y8kbqCnJDiBP3U1EbfFeRnY77+FD5eKUPvHnsnZ9L3o7cbsHrASLTlypzMy8IUFcCSvIi9O6ARi5PnZvLN0SixmTag/wE1iR6dbn5/iQAAAABJRU5ErkJggg=="
                alt="Img"
              />
              <Typography>{formatMessage({ id: 'safeup.peaceOfMind' })}</Typography>
            </Box>
            <Box>
              <Typography variant="subtitle1" color="primary" mb={1}>
                {formatMessage({ id: 'safeup.lowerCosts' })}
              </Typography>
              <img
                src="data:image/jpeg;base64,iVBORw0KGgoAAAANSUhEUgAAACkAAAAuCAYAAAC1ZTBOAAABVGlDQ1BJQ0MgUHJvZmlsZQAAGJV1kD9Iw1AQxr/UaqE6dLCbQwQF/0SRqotbrVgLHUKt+AcE0zSmQhKfSURcnJ0ddNHVVbeKk4MgzooFcXB1E4QsWuK9Rk2reI/jfnx897g7IAKFMSMKwLRcu5CdFpeWV8TYCwQkEac3rKgOS8tyniz4rq3hPZCb4m6E/9V7mzg7qe0NPRUvjw6ekx1//S0RL2uOSvWDckBltgsIfcTyjss4U6LbpqGI9znrAR9zLgV83vAUCxnia+KEWlHKxPfEUqlJ15vYNLbVrxn49F2atTDPK2UP8shCxCzmMPOPb6Lhy2ATDLuwsQEdFbjUlSaFwYBGnIMFFaOQiFMYo5zk9/19t1Dbon2mckDEDLU1Gbh45WuFWr9Eo9wAV6tMsZWfawpe1FkfTwXcWQXaD33/bRGIDQL1mu+/V32/fgq0PVKv9wlk+GFaLRDQ9QAAADhlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAAqACAAQAAAABAAAAKaADAAQAAAABAAAALgAAAABRyHp1AAADqElEQVRYCe2Zy08UQRCHf708Vx7KQwwuGCXRoGiMEhIvJh40URKNevKiF6/GkzFBPBj/AKMnwsF4MIoPIieDGpWg+IyioAhI5CkirorAyvJuq9lFaGZ2pmczO2ETOhnYra6u/qa6u7q7lg37OcciL65FzjeDtwRp1yhFhSdjld52fBKID6pOTgHj9IRb4mKAWHqYugFzyKZe8OoGsFPFM1Z5bStw5SkwNqHey6xmLA3cro1gR3YAy92zUtP/xpDP2sBPVwBxZDwIia6fwMMGwD9ualxXodML7NxAkB7daj1h6DlZ0wx+5ibwsVuvXfiyoREaBZo+Foo+ZG0L+Pk7wAebAS2AzVfVQtZ3gZfeBt51AlPTAV13/Fyblm+AWDw2FF5VD+45Ab6tFLj8BBjRn0LynKQNki2LBz9YCIhHFJcLbP/2wOdRWiy9v+fgA9Lw/07Qy/pGZx5eUgG2JgPYU6CxJ0NSWOD52WD0aAoB8luvgTftwHQEtnuKFryxG2z3JvKUHJ9kSEHmGwP3DsuMgzTZn7cB1+qAH4NynQPfZEjhoRcEc/HevK5J9ncM6KTQM+CbJ3fuowwpTm395Km6FucIFHrSrm6FRk6rRAWkPNyhXBRD75KZQltZUigNrdznp0U2ZEtMVYPMXgF29hDgSdfChJIQIL9wF2j6GkpDWa4GmUYePEABPSlB2TAGyZPX6xyE7PCCn7wK5HvUIdv7aWvtUtc30FTzpNi6Kl8amIlsVVSs7qiAVBtucSdZlwWsTlMfVy+Fny80L8O5ZizoRQ1y7Uqw8uNARvKC5gZfRQgquRE4NRmoqVSpQbrjaGXT8c1KCMpKBdItvJQBrRrk5+/gR8uBfVuB5EQDc8EqcUmraaKDSqu5roKGGqSYV48aA4+CUbtVomJ1L0HaNexR6skFNzW7vPHfTgKFM5ETslDk1e2iq6S4+xbmAUN01LK7iJ1rL4Wx7ODO1fdHqQcZUnixKA/s0jHwXxG4GVL6kK1fRad8CvJ+CmuVr8KAFE0SaTi25FhJHyp1JClRsOfnqoBPPZI41BfZk3palOrjPQN6NdZl4v7e3Afcfw/UUyZEMadkCsnLHgNlD6wD2djCfJmJ+41TRaSqdYopJDtcBORm6jS1WZSRAlaQQ0blZJXohZn+IibyQ9V0uHjbDi5ugNPBnKWdjHQEZJtzgWIKT6lujWVzSNFE5IhEyBBp5Ej8yieGmfKiEEkInaIGqdPQSZE+upMECn39AwuYANCM21p/AAAAAElFTkSuQmCC"
                alt="Img2"
              />
              <Typography>{formatMessage({ id: 'safeup.avoidHigherRepairCosts' })}</Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </BasicDialog>
  )
}
