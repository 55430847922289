const message = {
  contract_form: {
    title: 'Jaka jest forma umowy?',
    description:
      'W ramach subskrypcji występuje ramowa Główna Umowa Subskrypcji oraz załącznik na każdy wybrany przez Ciebie przedmiot (Umowa Subskrypcji).',
  },
  why_we_need_your_data: {
    title: 'Dlaczego potrzebujemy Twoich danych?',
    description:
      'Poprosimy Ciebie o podanie danych potrzebnych do weryfikacji oraz o akceptację umowy. Następnie otrzymasz mail z umową i prośbę o wpłatę kaucji.',
  },
  why_it_worth_to_subscribe: {
    title: 'Dlaczego warto subskrybować sprzęt?',
    description: `- Płacisz tylko za korzystanie ze sprzętu, nie jego pełną wartość\n
    - Krótki czas trwania Umowy umożliwia bycie na bieżąco z najnowszą technologią\n
    - Pakiety SafeUp i SafeUp+ zawierają ubezpieczenie, usługi serwisowe oraz usługi dodatkowe. WeSub pokrywa od 70% do 80% wartości szkód zawartych w wybranym pakiecie\n
    - Otrzymujesz jedną fakturę VAT, którą wygodnie księgujesz w koszty prowadzenia działalności
  `,
  },
  how_it_works: {
    title: 'W jaki sposób odbywa się zawarcie umowy?',
    description:
      'Umowę zawieramy całkowicie online. Po wygenerowaniu oferty wyślemy Ci mail z potwierdzeniem wybranych przez Ciebie przedmiotów oraz z linkiem do dokończenia transakcji. Wystarczy podać dane swojej firmy. Umowę generujemy automatycznie, a jej podpisanie odbywa się również online.',
  },
  // ---
  elastic_contract: {
    title: 'Elastyczna umowa',
    description:
      'Umowa trwa standardowo 12 miesięcy, ale już w połowie okresu subskrypcji możesz wymienić sprzęt na inny. Bądź zawsze na bieżąco.',
  },
  full_protection: {
    title: 'Pełna ochrona',
    description: 'Sprzęt zawsze jest ubezpieczony, skorzystaj z naszych dodatkowych pakietów ochronnych, aby korzystać bez zmartwień.',
  },
  vat_invoice: {
    title: 'Faktura VAT ',
    description: 'Comiesięczną fakturę VAT za usługę łatwo wrzucisz w koszty prowadzenia działalności.',
  },
  low_costs: {
    title: 'Niskie koszty',
    description: 'Subskrybując płacisz jedynie za korzystanie ze sprzętu, nie spłacasz jego pełnej wartości.',
  },
  prepare_id_card: 'Przygotuj dowód osobisty, będzie Ci potrzebny.',
  the_process_consists_of_steps: 'Proces składa się z {number_of_steps} kroków i średnio zajmuje nie więcej niż 10 minut.',
  address_was_provided_in_the_store:
    'Adres dostawy został podany przy zamówieniu w sklepie. Aby go zmienić, musisz przeprocesować zamówienie jeszcze raz.',
  address_for_plugin_transaction:
    'Przedmiot zostanie wysłany na adres siedziby firmy lub adres do doręczeń. Dokładny adres będziesz mógł podejrzeć w kolejnym kroku podczas generowania umowy.',
  penultimate_step_before_your_rent: 'To już ostatni krok dzielący Cię od subskrypcji wybranego produktu!',
  penultimate_step_before_your_rent_extension: 'To już ostatni krok dzielący Cię od przedłużenia subskrypcji wybranego produktu!',
  penultimate_step_before_your_loan: 'To już ostatni krok dzielący Cię od otrzymania wybranej pożyczki!',
  your_data_are_protected_with_SSL: 'Ważne, Twoje dane są chronione poprzez szyfrowanie SSL klasy bankowej.',
  your_data_are_protected_with_SSL_extended:
    'Ważne, Twoje dane są chronione poprzez szyfrowanie SSL klasy bankowej.\n Dla Twojej wygody dane zostały automatycznie uzupełnione z poprzedniej umowy. Możesz ich zmienić klikając przycisk "Zmień"',
  secure_SSL_payment: 'Bezpieczna płatność dzięki szyfrowaniu SSL.',
  business_account_transfer_required:
    'Tylko przelew z konta firmowego weryfikuje tożsamość, inne sposoby płatności wymagają dodatkowej weryfikacji.',
  to_pay_now: 'Do wpłaty teraz',
  data_loading_takes_longer_than_usual: 'Wczytywanie danych trwa dłużej niż zwykle',
  item_exchange_info:
    'Zaznacz, jeśli posiadasz już zawartą umowę subskrypcji i chcesz wymienić produkt na nowy z obecnie zawieranej umowy.',
  item_exchange_warning:
    'Upewnij się, że chcesz wymienić produkt, ponieważ jeśli podany nr umowy zostanie poprawnie zweryfikowany nie będzie możliwości cofnięcia akcji wymiany.',
  cookie_content:
    'Ta strona korzysta z plików cookies, aby świadczyć usługi na najwyższym poziomie. Dalsze korzystanie ze strony oznacza, że zgadzasz się na ich użycie.',
  offer_preparation_explanation:
    'Oferta subskrypcji przygotowywana jest przez firmę WeSub. Realizacja oferty odbywa się przy użyciu narzędzia RentPay dla produktów oferowanych przez wybrany e-commerce.',
  deposit_transfer: 'Kaucja zostanie przeksięgowana z poprzedniej Umowy.',
  marketingContentsAlreadyAccepted:
    'Zgody zostały zaakceptowane podczas zawierania pierwszej Umowy Subskrypcji. Jeżeli chcesz je wycofać zaloguj się do Portalu Klienta {link}.',
  enter_verification_code: 'Wprowadź 4-cyfrowy kod weryfikacyjny wysłany na numer telefonu: {phone}',
  phone_needed_to_sign_contract: 'Na podany numer telefonu zostanie wysłany kod weryfikacyjny, niezbędny do podpisania Umowy Subskrypcji.',
  phone_needed_to_sign_contract_loan:
    'Na podany numer telefonu zostanie wysłany kod weryfikacyjny, niezbędny do podpisania Umowy Pożyczki.',
  incorrect_verification_code: 'Kod weryfikacyjny jest niepoprawny',
  agreement_signing: 'Podpisywanie Umowy Subskrypcji',
  agreement_signing_loan: 'Podpisywanie Umowy Pożyczki',
  didnt_get_sms: 'SMS nie dotarł?',
  is_phone_incorrect: 'Numer telefony jest niepoprawny?',
  loan_preparation_info:
    'Oferta pożyczki przygotowywana jest przez firmę WeSub. Do otrzymania pożyczki niezbędne będzie wykonanie przelewu weryfikacyjnego na kwotę 1,23 zł.',
  identityCardNumber_required: 'W celu weryfikacji konieczne jest podanie numeru dowodu osobistego.',
  extension_data_automatically_filled:
    'Dla Twojej wygody dane zostały automatycznie uzupełnione z poprzedniej umowy. Możesz ich zmienić klikając przycisk "Edytuj"',
  your_data_is_protected: 'Twoje woje dane są bezpieczne!',
  why_we_need_sensitive_data:
    'Wykorzystujemy je wyłącznie do weryfikacji tożsamości i zawarcia umowy. Są szyfrowane i nieudostępniane osobom trzecim.',
}

export { message }
