import React, { ComponentType } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { IntlProvider } from 'react-intl'

import { ErrorFallback } from 'components/shared'
import { LanguageContextProvider } from 'context'
import { flatten } from 'flat'
import messages_pl from 'intl/pl'

function withIntl<T extends object>(WrappedComponent: ComponentType<T>) {
  return (props: T) => {
    return (
      <ErrorBoundary
        fallback={
          <IntlProvider locale="pl" messages={flatten(messages_pl)}>
            <ErrorFallback isPage />
          </IntlProvider>
        }
      >
        <LanguageContextProvider>
          <WrappedComponent {...props} />
        </LanguageContextProvider>
      </ErrorBoundary>
    )
  }
}
export { withIntl }
