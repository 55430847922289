import { SxProps, Theme } from '@mui/material'

export const containerStyle: SxProps<Theme> = {
  bgcolor: '#f5f8ff',
  px: { xs: 1, sm: 3, md: 8 },
  py: 3,
  textAlign: 'center',
  display: { xs: 'none', sm: 'block' },
  mt: 3,
}

export const contentStyle: SxProps<Theme> = {
  display: 'flex',
  flexDirection: { xs: 'column', sm: 'row' },
  alignItems: 'center',
  gap: { xs: 3, sm: 2 },
  textAlign: 'center',
}
