import { useCallback, useContext, useMemo } from 'react'
import { useIntl } from 'react-intl'

import { Box, Paper, useMediaQuery, useTheme } from '@mui/material'
import { Button, Card, StepTitle } from '@rent/ui'
import { ReactComponent as ArrowForwardIcon } from 'assets/icons/ArrowForward.svg'
import DefaultProductImg from 'assets/images/tokenWe.png'
import { StepContentLayout } from 'components/layouts'
import { NotReadyComponent, OrderSummaryPanel } from 'components/shared'
import { PromoCodeBox } from 'components/shared/orderSummary'
import { ErrorCodeType, STORAGE_ORDER_PRODUCTS, StepRoute } from 'consts'
import { NotificationsContext, notifyLoading } from 'context'
import { mapProducts } from 'helpers'
import { IMappedProduct } from 'models'

import { useStorageOrderProductsData } from '../hooks'
import { usePluginRouteParams, usePluginVendorData } from '../hooks/plugin'
import InsuranceSelection from './components/InsuranceSelection'
import ProductPrices from './components/ProductPrices'
import { usePromoCode } from './hooks'
import { gridItemContainerStyle } from './styles'

const StoreOrderSummaryPlugin = () => {
  const { formatMessage } = useIntl()
  const theme = useTheme()
  const matchesUpMd = useMediaQuery(theme.breakpoints.up('md'))

  const { dispatchNotification } = useContext(NotificationsContext)

  const { navigateTo } = usePluginRouteParams()

  const { data, loading } = usePluginVendorData({ shouldUpdateBannerStatus: true })

  const b2cOnline = false

  const onSetProductData = () => {
    if (data && orderProductsData.length === 0) {
      setOrderProductsData(
        mapProducts(
          [
            {
              id: data.id,
              product: data,
              quantityInOrder: 1,
              safeupPlusTaken: false,
            },
          ],
          b2cOnline,
          true,
        ),
      )
    }
  }
  const items = useMemo(() => (data ? [] : undefined), [data])

  // get products from the storage
  const { orderProductsData, setOrderProductsData } = useStorageOrderProductsData({
    items,
    orderId: data?.id?.toString(),
    refetchOrderData: onSetProductData,
  })

  // handle promo code
  const { promoCodeInfo, onSubmitPromoCodeForm, promoCode } = usePromoCode({
    orderProductsData,
    setOrderProductsData,
    dispatchNotification,
    originProductsData: data ? [data] : [],
  })

  const handleChangeInsurance = useCallback(
    (id: number, safeupPlusTaken: boolean) => {
      setOrderProductsData((prev: IMappedProduct[]) => {
        const updatedProducts = prev.map(el => ({
          ...el,
          safeupPlusTaken: el.id === id ? safeupPlusTaken : el.safeupPlusTaken,
        }))
        return updatedProducts
      })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [orderProductsData],
  )

  const submitStep = () => {
    notifyLoading(dispatchNotification)
    sessionStorage.setItem(
      STORAGE_ORDER_PRODUCTS,
      JSON.stringify({
        orderId: data?.id.toString(),
        orderProductsData,
      }),
    )
    navigateTo(StepRoute.PERSONAL_DATA)
  }

  return (
    <Box>
      <NotReadyComponent isLoading={loading} errorType={ErrorCodeType.ORDER_ERROR} redirect>
        <StepContentLayout
          // right panel
          rightSide={
            <OrderSummaryPanel
              isFirstStep
              items={orderProductsData}
              actionButton={
                <Button fullWidth id="goToPersonalData-button" onClick={submitStep} endIcon={<ArrowForwardIcon />}>
                  {formatMessage({ id: 'button.proceed_to_order' })}
                </Button>
              }
              promoBox={<PromoCodeBox promoInfo={promoCodeInfo} promoCode={promoCode} onSubmitForm={onSubmitPromoCodeForm} />}
            />
          }
        >
          <>
            {/* title */}
            <StepTitle
              title={`1. ${formatMessage({ id: 'your_offer' })}`}
              subtitle={formatMessage({ id: 'message.the_process_consists_of_steps' }, { number_of_steps: 3 })}
            />

            {/* products list with safeup selection */}
            {orderProductsData?.map((el: IMappedProduct) => {
              return (
                <Paper sx={{ p: { xs: 0.8, md: 1.6 }, '&:not(:last-child)': { mb: 2 } }} key={el.id}>
                  <>
                    <Box sx={gridItemContainerStyle}>
                      <Card
                        title={el.name}
                        img={DefaultProductImg}
                        sx={{ flexBasis: '80%', alignItems: { xs: 'flex-start', md: 'center' } }}
                      >
                        {!matchesUpMd && <ProductPrices product={el} />}
                      </Card>
                      {matchesUpMd && <ProductPrices product={el} />}
                    </Box>

                    {/* safeup radio */}
                    <InsuranceSelection
                      product={el}
                      handleSetSafeUpPlus={safeupPlusTaken => handleChangeInsurance(el.id, safeupPlusTaken)}
                    />
                  </>
                </Paper>
              )
            })}
          </>
        </StepContentLayout>
      </NotReadyComponent>
    </Box>
  )
}

export default StoreOrderSummaryPlugin
