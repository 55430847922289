import ApiEndpoints from 'api/endpoints'
import { OnError, OnSuccess, useMutate } from 'hooks'
import { IPromotionCart } from 'models'

export const usePostPromoCode = (onError: OnError, onSuccess: OnSuccess) => {
  return useMutate<IPromotionCart>(ApiEndpoints.postPromocode(), {
    onError,
    onSuccess,
  })
}

export const usePostSuperPromoCode = () => {
  return useMutate<IPromotionCart>(ApiEndpoints.postSuperPromocode())
}

export const useNotifySalesOnMultiPromo = (externalId: string) => {
  return useMutate(ApiEndpoints.postNotifySalesMultiPromo(externalId))
}
