const customer = {
  personal_data: 'Personal data',
  buyer_data: 'Buyer data',
  buyer_data_loan: 'Borrower data',
  client_data: 'Client data',
  contract_data: 'Contract data',
  try_later: 'Try later',
  delivery_method: 'Delivery method',
  formal_consents: 'Formal consents',
  required_consents: 'Required consents',
}

export { customer }
