import { memo, useMemo } from 'react'
import { useIntl } from 'react-intl'

import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import Paper from '@mui/material/Paper'
import Typography, { TypographyProps } from '@mui/material/Typography'
import { Card } from '@rent/ui'
import DefaultProductImg from 'assets/images/tokenWe.png'
import { Currency } from 'components/shared'
import { calcProductPrice, checkProductsHaveSameRentalPeriod } from 'helpers'
import { IMappedProduct } from 'models'

import { StyledIDivider, containerSpacings } from '../styledComponents'

const containerStyle = {
  mb: 1.2,
  ...containerSpacings,
}

interface PricesSummaryProps {
  items: IMappedProduct[]
  isFirstStep?: boolean
  isLoan?: boolean
}

const ProductsList = memo(({ items, isFirstStep, isLoan }: PricesSummaryProps) => {
  const { formatMessage } = useIntl()

  const calculatedProducts = useMemo(() => {
    return items.map(product => calcProductPrice(product))
  }, [items])

  const isSameRentalPeriod = checkProductsHaveSameRentalPeriod(items)

  return (
    <Paper sx={containerStyle}>
      {/* title */}
      <Typography variant="h5">{formatMessage({ id: isLoan ? 'your_offer_loan' : 'your_offer' })}</Typography>
      <StyledIDivider />

      {/* list of items */}
      {calculatedProducts.map((el, idx) => (
        <Box key={el.id} pb={idx !== items.length - 1 ? 1.6 : 0}>
          <Box display="flex" justifyContent="space-between">
            <Card title={el.name} img={isFirstStep ? undefined : DefaultProductImg} variant="body2">
              <Box>
                <ProductItem textId={isLoan ? 'installment' : 'price'}>
                  <Currency value={el.totalPriceWithDiscount} />
                </ProductItem>
                <ProductItem textId="amount">
                  {el.quantity} {formatMessage({ id: 'pcs' })}
                </ProductItem>

                {!isSameRentalPeriod && (
                  <ProductItem textId="subscription_period">
                    {el.rentalPeriod} {formatMessage({ id: 'months' })}
                  </ProductItem>
                )}

                {el.safeupPlusPrice && el.safeupPlusTaken && (
                  <ProductItem textId="insurance_extended" sx={{ my: 0.4 }}>
                    <Currency value={el.safeupPlusPrice} net="net" />
                  </ProductItem>
                )}
                {el.additionalServiceTaken && el.additionalServiceAmount && (
                  <>
                    <Typography variant="subtitle2">
                      {formatMessage({ id: 'additional_services' })}{' '}
                      {formatMessage({ id: 'services_count' }, { count: el.additionalServiceCount })}:{' '}
                      <Currency value={el.additionalServiceAmount} net="net" />
                    </Typography>
                  </>
                )}
              </Box>
            </Card>
          </Box>
          {idx !== items.length - 1 && <Divider sx={{ pt: 1.6 }} />}
        </Box>
      ))}
    </Paper>
  )
})

interface ProductItemProps extends TypographyProps {
  textId: string
  children: React.ReactNode
}

export const ProductItem = ({ textId, children, ...otherProps }: ProductItemProps) => {
  const { formatMessage } = useIntl()
  return (
    <Typography variant="subtitle2" {...otherProps}>
      {formatMessage({ id: textId })}: {children}
    </Typography>
  )
}

export default ProductsList
