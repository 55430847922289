/* eslint-disable @typescript-eslint/quotes */
/* eslint-disable quotes */

const message = {
  contract_form: {
    title: 'What is the contract form?',
    description:
      'The subscription includes a framework Master Subscription Agreement and an attachment for each selected item (Subscription Agreement).',
  },
  why_we_need_your_data: {
    title: 'Why do we need your data?',
    description:
      'We will ask you to provide the necessary data for verification and to accept the contract. Then, you will receive an email with the contract and a request for a deposit payment.',
  },
  why_it_worth_to_subscribe: {
    title: 'Why is it worth subscribing to equipment?',
    description:
      '- You only pay for using the equipment, not its full value\n- The short contract duration allows you to stay up to date with the latest technology\n- SafeUp and SafeUp+ packages include insurance, service, and additional services. WeSub covers 70% to 80% of damages included in the selected package\n- You receive a single VAT invoice that you can conveniently account for as a business expense',
  },
  how_it_works: {
    title: 'How is the contract concluded?',
    description:
      'The contract is concluded entirely online. After generating an offer, we will send you an email with confirmation of your selected items and a link to complete the transaction. Just provide your company details. The contract is generated automatically and is signed online.',
  },
  elastic_contract: {
    title: 'Flexible contract',
    description:
      'The contract lasts for 12 months, but after half the subscription period, you can exchange the equipment for another. Stay up to date.',
  },
  full_protection: {
    title: 'Full protection',
    description: 'The equipment is always insured. Take advantage of our additional protection packages to use without worries.',
  },
  vat_invoice: {
    title: 'VAT Invoice',
    description: 'The monthly VAT invoice for the service can be easily included in your business expenses.',
  },
  low_costs: {
    title: 'Low costs',
    description: 'By subscribing, you only pay for using the equipment, not for its full value.',
  },
  prepare_id_card: 'Prepare your ID card, as you will need it.',
  the_process_consists_of_steps: 'The process consists of {number_of_steps} steps and usually takes no more than 10 minutes.',
  address_was_provided_in_the_store:
    'The delivery address was provided when ordering in the store. To change it, you need to process the order again.',
  address_for_plugin_transaction:
    "The item will be sent to the company's headquarters or a delivery address. You will be able to review the exact address in the next step while generating the contract.",
  penultimate_step_before_your_rent: 'This is the last step before subscribing to your selected product!',
  penultimate_step_before_your_rent_extension: 'This is the last step before extending your subscription for the selected product!',
  penultimate_step_before_your_loan: 'This is the last step before receiving your selected loan!',
  your_data_are_protected_with_SSL: 'Important: Your data is protected with bank-grade SSL encryption.',
  your_data_are_protected_with_SSL_extended:
    "Important: Your data is protected with bank-grade SSL encryption.\n For your convenience, the data has been automatically filled in from the previous contract. You can change it by clicking the 'Change' button.",
  secure_SSL_payment: 'Secure payment with SSL encryption.',
  business_account_transfer_required:
    'Only a transfer from a business account verifies identity. Other payment methods require additional verification.',
  to_pay_now: 'Amount to pay now',
  data_loading_takes_longer_than_usual: 'Data loading is taking longer than usual',
  item_exchange_info:
    'Check this box if you already have an active subscription contract and want to exchange your product for a new one under the current agreement.',
  item_exchange_warning:
    'Make sure you want to exchange the product, as once the contract number is successfully verified, the exchange action cannot be undone.',
  cookie_content:
    'This website uses cookies to provide the highest level of service. Continuing to use the site means you agree to their use.',
  offer_preparation_explanation:
    'The subscription offer is prepared by WeSub. The offer is executed using the RentPay tool for products offered by the selected e-commerce platform.',
  deposit_transfer: 'The deposit will be transferred from the previous contract.',
  marketingContentsAlreadyAccepted:
    'Consents were accepted when signing the first Subscription Agreement. If you want to withdraw them, log in to the Customer Portal {link}.',
  enter_verification_code: 'Enter the 4-digit verification code sent to the phone number: {phone}',
  phone_needed_to_sign_contract:
    'A verification code required to sign the Subscription Agreement will be sent to the provided phone number.',
  phone_needed_to_sign_contract_loan: 'A verification code required to sign the Loan Agreement will be sent to the provided phone number.',
  incorrect_verification_code: 'The verification code is incorrect',
  agreement_signing: 'Signing the Subscription Agreement',
  agreement_signing_loan: 'Signing the Loan Agreement',
  didnt_get_sms: "Didn't receive the SMS?",
  is_phone_incorrect: 'Is the phone number incorrect?',
  loan_preparation_info: 'The loan offer is prepared by WeSub. To receive the loan, a verification transfer of 1.23 PLN will be required.',
  identityCardNumber_required: 'To verify your identity, providing your ID card number is required.',
  extension_data_automatically_filled:
    "For your convenience, the data has been automatically filled in from the previous contract. You can change it by clicking the 'Edit' button.",
  your_data_is_protected: 'Your data is protected!',
  why_we_need_sensitive_data:
    'We use it solely for identity verification and contract conclusion. It is encrypted and not shared with third parties',
}

export { message }
