/* eslint-disable @typescript-eslint/quotes */
/* eslint-disable quotes */

const form = {
  firstName_label: 'First name',
  lastName_label: 'Last name',
  firstName_lastName_label: 'First name and last name',
  nip_label: 'NIP number',
  buyerEmail_label: 'Email address',
  buyerPhone_label: 'Phone number',
  firstName_placeholder: 'Enter first name',
  lastName_placeholder: 'Enter last name',
  nip_placeholder: 'Enter NIP number',
  buyerEmail_placeholder: 'Enter email address',
  buyerPhone_placeholder: 'Enter phone number',

  purpose_of_asking_for_a_phone_number: 'We use the phone number for contract verification.',

  agreementRodo_label: 'I declare that I have read the content of the information clause available on the website {link}.',

  agreementStatute_label: 'I have read the content and terms of {link} and agree to comply with them unconditionally.',

  agreementKrd_label: 'I consent to verification.',
  agreementKrd_label_expanded:
    'I consent to verification and authorize WeSub S.A., based in Warsaw, to request disclosure of economic information concerning me from Krajowy Rejestr Długów Biura Informacji Gospodarczej S.A..',

  agreementEmailCommunication_label: 'I consent to email communication.',
  agreementEmailCommunication_label_expanded:
    'I consent to WeSub S.A. processing my personal data in the form of my email address to send me marketing information about products and services offered by WeSub S.A. via electronic communication means, in accordance with Article 10(1) and (2) of the Act on the Provision of Electronic Services.',

  agreementPhoneCommunication_label: 'I consent to phone communication.',
  agreementPhoneCommunication_label_expanded:
    'I consent to the processing of my personal data in the form of my provided phone number by WeSub S.A. for marketing activities using telecommunication terminal equipment and automated calling systems under the Telecommunications Law.',

  provide_promocode_placeholder: 'Enter promo code',
  representative_label: 'Representative',
  representative_placeholder: 'Select a representative',
  pesel_label: 'PESEL',
  pesel_placeholder: 'Enter PESEL',
  identityCardNumber_label: 'ID card series and number',
  identityCardNumber_placeholder: 'Enter ID card series and number',
  identityCardValidFrom_label: 'ID card issue date',
  identityCardValidTo_label: 'ID card expiration date',

  returnAccountNumber_label: 'Refund account number',
  returnAccountNumber_placeholder: 'Enter refund account number',

  agreementAdministrator_label: 'I declare that I have read the content of the information clause.',
  agreementAdministrator_label_expanded:
    'The administrator of personal data concerning the Service Recipient and persons involved on their side in executing the General Subscription Agreement and the Subscription Agreement is WeSub S.A., based in Warsaw at Branickiego 17. Personal data is processed to properly execute the General Subscription Agreement and the Subscription Agreement based on Article 6(1)(b) of GDPR and will also be processed based on Articles 6(1)(c) and 6(1)(f) of GDPR. The basis for processing personal data is the conclusion and execution of the General Subscription Agreement and the Subscription Agreement. The Service Recipient has the right to access their data in accordance with Article 15 of GDPR, the right to request rectification, deletion, restriction of processing under Article 18 of GDPR, the right to data portability, the right to object to processing, and the right to lodge a complaint with the supervisory authority, i.e., the President of the Personal Data Protection Office. The full content of the information clause is available on the website: {link}. I also declare that I have read the content of the information clause available on the aforementioned website.',

  agreementAdministratorLoan_label: 'I declare that I have read the content of the information clause.',
  agreementAdministratorLoan_label_expanded:
    'The administrator of personal data concerning the Borrower and persons involved on their side in executing the Loan Agreement is WeSub S.A., based in Warsaw at Branickiego 15. Personal data is processed to properly execute the Loan Agreement based on Article 6(1)(b) of GDPR and will also be processed based on Articles 6(1)(c) and 6(1)(f) of GDPR. The basis for processing personal data is the conclusion and execution of the Loan Agreement. The Borrower has the right to access their data in accordance with Article 15 of GDPR, the right to request rectification, deletion, restriction of processing under Article 18 of GDPR, the right to data portability, the right to object to processing, and the right to lodge a complaint with the supervisory authority, i.e., the President of the Personal Data Protection Office. The full content of the information clause is available on the website: {link}. I also declare that I have read the content of the information clause available on the aforementioned website.',

  agreementContractor_label: "Due to the requirements for identifying the contractor's status.",
  agreementContractor_label_expanded:
    "Due to the requirements for identifying the contractor's status resulting from the Consumer Rights Act of May 30, 2014 (Journal of Laws 2020.287, as amended), we kindly ask you to check the box confirming that, according to the data contained particularly in the Central Register and Information on Economic Activity, the Subscription Agreement is concluded directly in connection with the User's business activity and has a professional nature for them. I declare that the above statement is factually and legally accurate. A false statement misleading the Service Provider after making the statement may constitute grounds for liability for damages and entitle the Service Provider to claim compensation for the resulting loss.",
  agreementAcceptance_label:
    'I have read the content of the General Subscription Agreement and the Subscription Agreement, as well as the terms of the regulations, and I accept their conditions.',
  agreementAcceptance_label_expanded:
    'I have read the content of the General Subscription Agreement {frameworkAgreementExposedId} (hereinafter: General Subscription Agreement), the Subscription Agreement {leaseAgreementExposedId} (hereinafter: Subscription Agreement), and the terms of {link1} and accept all conditions arising from the above agreements. I undertake to comply with and fulfill the provisions of the General Subscription Agreement, the Subscription Agreement, and the annexes to these agreements, which form an integral part of them. I declare and guarantee that there are no restrictions on entering into the aforementioned agreements, and the person making this declaration electronically is duly authorized. I acknowledge that, under Article {art} of the Civil Code, a declaration of intent made electronically, i.e., in this case, by checking the checkbox or providing the SMS Code, is equivalent to a declaration of intent made in documentary form and confirms the will to enter into the General Subscription Agreement and the Subscription Agreement.',

  agreementAcceptanceLoan_label:
    'I have read the content of the Loan Agreement and the terms of the regulations, and I accept their conditions.',
  agreementAcceptanceLoan_label_expanded:
    'I have read the content of the Loan Agreement GUS/2024/08/00008/PO01 (hereinafter: Loan Agreement) and the terms of {link1}, the fee and commission table, and I accept all conditions arising from the above agreement and annexes. I undertake to comply with and fulfill the provisions of the Loan Agreement and its integral annexes. I declare and guarantee that there are no restrictions on entering into the aforementioned agreement, and the person making this declaration electronically is duly authorized. I acknowledge that, under Article {art} of the Civil Code, a declaration of intent made electronically, i.e., in this case, by checking the checkbox or providing the SMS Code, is equivalent to a declaration of intent made in documentary form and confirms the will to enter into the Loan Agreement.',

  of_terms_and_toip: 'regulations and the fee and commission table',
  want_to_exchange_item_from_another_agreement: 'I want to exchange an item from another Subscription Agreement',
  item_exchange_label: 'Agreement number of the item subject to exchange',
}

export { form }
