import { ContractGenerationValidationSchema } from 'components/pages/ContractGeneration/consts'
import dayjs from 'dayjs'
import { ICustomerOrder, IMappedContractAcceptanceData } from 'models'

export const mapContractAcceptanceData = (
  formData: ContractGenerationValidationSchema,
  orderData: ICustomerOrder,
): IMappedContractAcceptanceData => {
  const {
    nameAndLastName,
    pesel,
    identityCardNumber,
    identityCardValidFrom,
    identityCardValidTo,
    returnAccountNumber,
    agreementAdministrator,
    agreementAcceptance,
    agreementContractor,
    buyerPhone,
  } = formData

  const data = {
    buyerEmail: orderData?.buyerEmail ?? '',
    firstName: orderData?.firstName ?? '',
    lastName: orderData?.lastName ?? '',
    nip: String(orderData?.nip),

    buyerPhone,
    reprezentant: nameAndLastName,
    pesel,
    identityCardNumber,
    identityCardValidFrom: dayjs(identityCardValidFrom).format('YYYY-MM-DD'),
    identityCardValidTo: dayjs(identityCardValidTo).format('YYYY-MM-DD'),

    returnAccountNumber,

    finalRodoAgreementAccepted: agreementAdministrator,
    finalContrahentProcessingAccepted: agreementContractor,
    finalStatuteAccepted: agreementAcceptance,
  }

  return data
}

export const checkContractAcceptanceDataMatchInterface = (data: IMappedContractAcceptanceData): boolean => {
  if (
    typeof data.buyerEmail !== 'string' ||
    typeof data.firstName !== 'string' ||
    typeof data.lastName !== 'string' ||
    typeof data.nip !== 'string' ||
    typeof data.reprezentant !== 'string' ||
    typeof data.pesel !== 'string' ||
    typeof data.identityCardNumber !== 'string' ||
    typeof data.identityCardValidFrom !== 'string' ||
    typeof data.identityCardValidTo !== 'string' ||
    typeof data.buyerPhone !== 'string' ||
    typeof data.finalRodoAgreementAccepted !== 'boolean' ||
    typeof data.finalStatuteAccepted !== 'boolean' ||
    typeof data.finalContrahentProcessingAccepted !== 'boolean'
  ) {
    return false
  } else return true
}

export const mapInitFormData = (formData: IMappedContractAcceptanceData) => {
  const { reprezentant, pesel, identityCardNumber, identityCardValidFrom, identityCardValidTo, returnAccountNumber } = formData

  const data = {
    nameAndLastName: reprezentant,
    pesel: pesel ?? '',
    identityCardNumber: identityCardNumber ?? '',
    identityCardValidFrom: identityCardValidFrom ? dayjs(identityCardValidFrom).format('YYYY-MM-DD') : '',
    identityCardValidTo: identityCardValidFrom ? dayjs(identityCardValidTo).format('YYYY-MM-DD') : '',

    returnAccountNumber: returnAccountNumber ?? '',

    replacementAgreement: false,
    replacementAgreementVerified: false,
    replacementAgreementNumber: '',

    selectAll: false,
    agreementAdministrator: false,
    agreementContractor: false,
    agreementGenerated: false,
    agreementAcceptance: false,
  }

  return data
}
