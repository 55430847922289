import { useMemo, useState } from 'react'
import { useIntl } from 'react-intl'

import { Box, Tooltip } from '@mui/material'
import Typography from '@mui/material/Typography'
import { CheckboxGroup, MainAccordion } from '@rent/ui'
import { ReactComponent as InfoCircleIcon } from 'assets/icons/InfoCircle.svg'
import { AdditionalService, IMappedProduct } from 'models'

import { AdditionalServiceContent } from './AdditionalServiceContent'
import { AdditionalServiceUrlLink } from './AdditionalServiceUrlLink'

interface AdditionalServicesSelectionProps {
  additionalServices: AdditionalService[]
  product: IMappedProduct
  handleSelectServices: (val: string[]) => void
}

const AdditionalServicesSelection = ({ additionalServices, handleSelectServices, product }: AdditionalServicesSelectionProps) => {
  const { formatMessage } = useIntl()
  const [selectedService, setSelectedService] = useState<AdditionalService | null>(null)
  const [isAccordionActive, setIsAccordionActive] = useState(true)

  const handleOpenDialog = (e: React.MouseEvent, service: AdditionalService) => {
    e.preventDefault()
    setSelectedService(service)
  }

  const additionalServicesData = useMemo(() => {
    if (!additionalServices?.length) {
      return []
    }

    return additionalServices.map(el => ({
      title: (
        <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
          {el.name}
          {el.content && (
            <Tooltip title={formatMessage({ id: 'button.see_more' })} placement="top">
              <Box sx={{ display: 'flex', '& svg': { width: '1.8rem', height: 'auto' } }}>
                <InfoCircleIcon onClick={e => handleOpenDialog(e, el)} />
              </Box>
            </Tooltip>
          )}
        </Box>
      ),
      id: el.id,
      content: (
        <>
          {el.description && <Box dangerouslySetInnerHTML={{ __html: el.description }} />}
          {el.url && <AdditionalServiceUrlLink url={el.url} mt={0.5} />}
        </>
      ),
      rightText: (
        <>
          {el.b2bAmount} {formatMessage({ id: 'PLN' })}{' '}
          <Typography variant="inherit" component="span" sx={{ fontSize: '1.4rem' }}>
            {formatMessage({ id: 'net' })}/{formatMessage({ id: 'mth' })}
          </Typography>
        </>
      ),
    }))
  }, [additionalServices, formatMessage]) // Fixed dependencies array

  const handleChange = (serviceIds: string[]) => {
    handleSelectServices(serviceIds.map(el => el.toString()))
  }
  const handleSelectService = (serviceId: string) => {
    const alreadyAdded = product.additionalServiceIds?.includes(serviceId)
    if (alreadyAdded) {
      setSelectedService(null)
      return
    }
    handleSelectServices([...product.additionalServiceIds, serviceId])
    setSelectedService(null)
  }

  return (
    <>
      {additionalServicesData.length > 0 && (
        <>
          <MainAccordion
            title={
              <Typography variant="h6" sx={{ mb: 1, mt: 1.6 }}>
                {formatMessage({ id: 'additional_services' })}
                {!isAccordionActive && (
                  <Typography component="span" variant="body2" sx={{ ml: 0.4 }}>
                    {formatMessage({ id: 'selected' }, { count: product.additionalServiceCount })}
                  </Typography>
                )}
              </Typography>
            }
            defaultOpen
            handleToggleCallback={setIsAccordionActive}
          >
            <CheckboxGroup
              items={additionalServicesData}
              sx={{ '&:not(:last-child)': { mb: 1 } }}
              selectedValues={product.additionalServiceIds}
              onChange={handleChange}
            />
          </MainAccordion>
          {selectedService && (
            <AdditionalServiceContent
              additionalService={selectedService}
              onClose={() => setSelectedService(null)}
              handleSelectService={handleSelectService}
            />
          )}
        </>
      )}
    </>
  )
}

export default AdditionalServicesSelection
