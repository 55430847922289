import { useCallback, useContext, useMemo } from 'react'
import { useIntl } from 'react-intl'
import { useNavigate } from 'react-router-dom'

import { Box, Paper, useMediaQuery, useTheme } from '@mui/material'
import { Button, Card, StepTitle } from '@rent/ui'
import { ReactComponent as ArrowForwardIcon } from 'assets/icons/ArrowForward.svg'
import DefaultProductImg from 'assets/images/tokenWe.png'
import { StepContentLayout } from 'components/layouts'
import { NotReadyComponent, OrderSummaryPanel } from 'components/shared'
import { PromoCodeBox } from 'components/shared/orderSummary'
import { ErrorCodeType, GeneralRoute, OrderStatus, STORAGE_ORDER_PRODUCTS, StepRoute } from 'consts'
import { BranchType } from 'consts/branchType'
import { NotificationsContext, notifyLoading } from 'context'
import { mapProducts } from 'helpers'
import { useExtensionTokenSearch } from 'hooks'
import { AdditionalService, IMappedProduct } from 'models'

import { useCancelNewOrder, useOrderData, usePreviewOnly, useStorageOrderProductsData, useVendorData } from '../hooks'
import AdditionalServicesSelection from './components/AdditionalServicesSelection'
import InsuranceSelection from './components/InsuranceSelection'
import ProductPrices from './components/ProductPrices'
import { usePromoCode } from './hooks'
import { gridItemContainerStyle } from './styles'

const StoreOrderSummary = () => {
  const { formatMessage } = useIntl()
  const theme = useTheme()
  const matchesUpMd = useMediaQuery(theme.breakpoints.up('md'))

  const navigate = useNavigate()
  const { extensionTokenSearch } = useExtensionTokenSearch()

  const { dispatchNotification } = useContext(NotificationsContext)

  // get order data
  const { data, isLoading, isSuccess, isError, error, refetchOrderData } = useOrderData({
    status: [OrderStatus.NEW, OrderStatus.PREVIEW_ONLY, OrderStatus.PRE_ORDER],
    isUseContextData: true,
    shouldGetAdditionalServices: true,
  })
  // get vendor data and set logo
  useVendorData({ vendorId: data?.vendorId })

  const isLoan = data?.branch === BranchType.CREDIT

  const isPreviewOnly = usePreviewOnly({ status: data?.status }) && !isLoading && isSuccess

  const b2cOnline = data?.b2cOnline
  const refetchOrder = () => {
    if (data?.items) {
      setOrderProductsData(mapProducts(data?.items, b2cOnline, true, data?.extension || false))
    } else refetchOrderData()
  }

  // get products from the storage
  const { orderProductsData, setOrderProductsData } = useStorageOrderProductsData({
    items: data?.items,
    orderId: data?.externalId,
    refetchOrderData: refetchOrder,
  })

  // cancel on page reload or tab close
  useCancelNewOrder({ orderId: data?.externalId ?? '', status: data?.status, refetchOrderData })

  // handle promo code
  const { promoCodeInfo, onSubmitPromoCodeForm, promoCode } = usePromoCode({
    orderProductsData,
    setOrderProductsData,
    dispatchNotification,
    originProductsData: data?.items ? data?.items.map(el => el.product) : [],
    disabled: isLoan,
    externalId: data?.externalId,
    returnURL: data?.returnURL,
  })

  const handleChangeInsurance = useCallback(
    (id: number, safeupPlusTaken: boolean) => {
      setOrderProductsData((prev: IMappedProduct[]) => {
        const updatedProducts = prev.map(el => ({
          ...el,
          safeupPlusTaken: el.id === id ? safeupPlusTaken : el.safeupPlusTaken,
        }))
        return updatedProducts
      })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [orderProductsData],
  )

  const availableAdditionalServices = useMemo(() => {
    if (!data?.items?.length) return {}

    return data.items.reduce(
      (acc, el) => ({
        ...acc,
        [el.product.id]: el.product.additionalServices
          .filter(s => s.active && s.b2bAmount)
          .map(s => ({ ...s, b2bAmount: Number(s.b2bAmount) * el.quantityInOrder, id: String(s.id) })),
      }),
      {} as Record<string, AdditionalService[]>,
    )
  }, [data?.items])

  const handleSelectServices = useCallback(
    (id: number, vals: string[]) => {
      setOrderProductsData((prev: IMappedProduct[]) => {
        const selected = availableAdditionalServices[id].filter(el => vals.includes(el.id.toString()))
        return prev.map(product => {
          if (product.id !== id) {
            return product
          }

          const selectedServiceIds = selected.map(service => service.id.toString())
          const totalServiceAmount = selected.reduce((sum, service) => sum + Number(service.b2bAmount), 0)

          return {
            ...product,
            additionalServiceIds: selectedServiceIds,
            additionalServiceAmount: totalServiceAmount,
            additionalServiceCount: selected.length,
            additionalServiceTaken: selected.length > 0,
          }
        })
      })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [orderProductsData],
  )

  const submitStep = () => {
    notifyLoading(dispatchNotification)
    sessionStorage.setItem(
      STORAGE_ORDER_PRODUCTS,
      JSON.stringify({
        orderId: data?.externalId,
        orderProductsData,
      }),
    )
    navigate({
      pathname: `/${GeneralRoute.ORDER}/${data?.externalId}/${StepRoute.PERSONAL_DATA}`,
      search: extensionTokenSearch,
    })
  }

  return (
    <Box>
      <NotReadyComponent
        isLoading={isLoading}
        isSuccess={isSuccess}
        isError={isError}
        error={error}
        errorType={ErrorCodeType.ORDER_ERROR}
        redirect
      >
        <StepContentLayout
          // right panel
          rightSide={
            <OrderSummaryPanel
              isFirstStep
              items={orderProductsData}
              actionButton={
                !isPreviewOnly && (
                  <Button fullWidth id="goToPersonalData-button" onClick={submitStep} endIcon={<ArrowForwardIcon />}>
                    {formatMessage({ id: isLoan ? 'button.go_further' : 'button.proceed_to_order' })}
                  </Button>
                )
              }
              promoBox={
                !isPreviewOnly &&
                !isLoan && <PromoCodeBox promoInfo={promoCodeInfo} promoCode={promoCode} onSubmitForm={onSubmitPromoCodeForm} />
              }
            />
          }
        >
          <>
            {/* title */}
            <StepTitle
              title={`1. ${formatMessage({ id: isLoan ? 'your_offer_loan' : 'your_offer' })}`}
              subtitle={formatMessage({ id: 'message.the_process_consists_of_steps' }, { number_of_steps: 3 })}
            />

            {/* products list with safeup selection */}
            {orderProductsData?.map((el: IMappedProduct) => {
              return (
                <Paper sx={{ p: { xs: 0.8, md: 1.6 }, '&:not(:last-child)': { mb: 2 } }} key={el.id}>
                  <>
                    <Box sx={[gridItemContainerStyle, isLoan && { mb: 0 }]}>
                      <Card
                        title={el.name}
                        img={DefaultProductImg}
                        sx={{ flexBasis: '80%', alignItems: { xs: 'flex-start', md: 'center' } }}
                      >
                        {!matchesUpMd && <ProductPrices product={el} />}
                      </Card>
                      {matchesUpMd && <ProductPrices product={el} />}
                    </Box>

                    {/* safeup radio */}
                    {!isLoan && (
                      <InsuranceSelection
                        product={el}
                        handleSetSafeUpPlus={safeupPlusTaken => handleChangeInsurance(el.id, safeupPlusTaken)}
                      />
                    )}
                    {!isLoan && (
                      <AdditionalServicesSelection
                        additionalServices={availableAdditionalServices[el.id]}
                        product={el}
                        handleSelectServices={vals => handleSelectServices(el.id, vals)}
                      />
                    )}
                  </>
                </Paper>
              )
            })}
          </>
        </StepContentLayout>
      </NotReadyComponent>
    </Box>
  )
}

export default StoreOrderSummary
