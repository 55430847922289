import { useIntl } from 'react-intl'

import { Link, Typography, TypographyProps } from '@mui/material'

interface AdditionalServiceUrlLinkProps extends TypographyProps {
  url: string
}
export const AdditionalServiceUrlLink = ({ url, ...otherProps }: AdditionalServiceUrlLinkProps) => {
  const { formatMessage } = useIntl()

  return (
    <Typography variant="inherit" {...otherProps}>
      {formatMessage(
        { id: 'button.click_here_to_learn_more' },
        {
          link: (
            <Link target="_blank" href={url} rel="noreferrer" color="text.primary" id="rodo-link">
              {formatMessage({ id: 'button.here', defaultMessage: 'here' })}
            </Link>
          ),
        },
      )}
    </Typography>
  )
}
