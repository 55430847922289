import ApiEndpoints from 'api/endpoints'
import { useRequest } from 'hooks'
import { ICustomerOrder } from 'models'

/**
 * The function `useGetCustomerOrder` is a custom hook that makes a request to retrieve a customer
 * order based on the provided order ID.
 */
export const useGetCustomerOrder = (orderId: string, shouldGetAdditionalServices?: boolean) => {
  return useRequest<ICustomerOrder>(
    shouldGetAdditionalServices ? ApiEndpoints.getOrderWithAdditionalServices(orderId) : ApiEndpoints.getOrder(orderId),
    { enabled: !!orderId, retry: 2 },
  )
}
