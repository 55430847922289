import { useContext, useEffect, useMemo, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { useIntl } from 'react-intl'
import { Outlet, useLocation, useOutletContext } from 'react-router-dom'

import { Box, Container, Typography, useMediaQuery, useTheme } from '@mui/material'
import { Header, InfoBar, LanguageSwitch, Stepper } from '@rent/ui'
import { ReactComponent as LockIcon } from 'assets/icons/Lock.svg'
import RentPayForLogo from 'assets/images/RentPayBiznes_for.png'
import { usePreviewOnly } from 'components/pages/hooks'
import { StepRoute } from 'consts/routing'
import { OrderContext, useLanguageContext } from 'context'
import { ICustomerOrder } from 'models'

import StepSubHeader from './StepSubHeader'

type VendorImgType = { src: string; alt: string } | null

export type StepLayoutContextType = {
  vendorImg: VendorImgType
  setVendorImg: (val: VendorImgType) => void
  orderProducts: ICustomerOrder | null
  setOrderProducts: (val: ICustomerOrder | null) => void
}

const getSteps = (formatMessage: ({ id }: { id: string }) => string) => [
  { key: StepRoute.STORE_ORDER_SUMMARY, title: formatMessage({ id: 'your_offer' }) },
  { key: StepRoute.PERSONAL_DATA, title: formatMessage({ id: 'personal_data' }) },
  { key: StepRoute.CONTRACT_GENERATION, title: formatMessage({ id: 'contract_generation' }) },
]

const StepLayout = () => {
  const { formatMessage } = useIntl()
  const { pathname } = useLocation()
  const { breakpoints } = useTheme()
  const matchesDesktop = useMediaQuery(breakpoints.up('md'))

  const { contextData } = useContext(OrderContext)

  const { locale, setLocale } = useLanguageContext()

  const [vendorImg, setVendorImg] = useState<VendorImgType>(null)
  const [orderProducts, setOrderProducts] = useState<ICustomerOrder | null>(null)

  const steps = useMemo(() => getSteps(formatMessage), [formatMessage])
  const isPreviewOnly = usePreviewOnly({ status: orderProducts?.status })

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  const pageTitle = useMemo(() => {
    switch (true) {
      case pathname.includes(StepRoute.STORE_ORDER_SUMMARY):
        return steps.find(el => el.key === StepRoute.STORE_ORDER_SUMMARY)
      case pathname.includes(StepRoute.PERSONAL_DATA):
        return steps.find(el => el.key === StepRoute.PERSONAL_DATA)
      case pathname.includes(StepRoute.CONTRACT_GENERATION):
        return steps.find(el => el.key === StepRoute.CONTRACT_GENERATION)

      default:
        return steps.find(el => el.key === StepRoute.STORE_ORDER_SUMMARY)
    }
  }, [pathname, steps])

  const isFirstStep = pathname.includes(StepRoute.STORE_ORDER_SUMMARY)

  return (
    <Box sx={{ position: 'relative' }}>
      <Helmet>
        <title>RentPay - {pageTitle?.title}</title>
      </Helmet>
      <InfoBar
        variant="contained"
        color="success"
        sx={{ p: 0.4 }}
        rounded={false}
        title={formatMessage({ id: 'safe_purchase' })}
        icon={LockIcon}
      />
      <Header
        img={[{ src: RentPayForLogo, alt: 'RentPayForLogo' }, vendorImg ?? {}]}
        subHeader={!isFirstStep && <StepSubHeader isLoan={contextData.isLoan} />}
        rightEl={
          !matchesDesktop && (
            <Box sx={{ position: 'absolute', top: -25, right: 5 }}>
              <LanguageSwitch locale={locale} setLocale={setLocale} sx={{ '& .MuiButtonBase-root': { py: 0 } }} />
            </Box>
          )
        }
      >
        {isPreviewOnly ? (
          <Typography variant="h4" mb={{ xs: 2, md: 0 }} ml={{ sm: 0, md: 4 }}>
            {formatMessage({ id: 'offer_preview' })}
          </Typography>
        ) : (
          ((orderProducts && isFirstStep) || !isFirstStep) && (
            <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
              <Stepper steps={steps} activeStep={pageTitle?.key ?? ''} />
            </Box>
          )
        )}
        {matchesDesktop && <LanguageSwitch locale={locale} setLocale={setLocale} />}
      </Header>

      <Container>
        <Outlet
          context={
            {
              vendorImg,
              setVendorImg,
              orderProducts,
              setOrderProducts,
            } satisfies StepLayoutContextType
          }
        />
      </Container>
    </Box>
  )
}

export function useStepLayoutContext() {
  return useOutletContext<StepLayoutContextType>()
}

export default StepLayout
