// contexts/LanguageContext.tsx
import { createContext, useContext, useEffect, useState } from 'react'
import { IntlProvider } from 'react-intl'

import { STORAGE_LANGUAGE } from 'consts'
import { flatten } from 'flat'
import messages from 'intl'

type SupportedLocales = 'en' | 'pl'
const supportedLocales: SupportedLocales[] = ['en', 'pl']

type LanguageContextType = {
  locale: SupportedLocales
  setLocale: (locale: SupportedLocales) => void
}

const LanguageContext = createContext<LanguageContextType>({
  locale: 'pl',
  setLocale: () => null,
})

export const LanguageContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [locale, setLocale] = useState<SupportedLocales>('pl')

  useEffect(() => {
    const savedLanguage = localStorage.getItem(STORAGE_LANGUAGE)
    const finalLocale = supportedLocales.includes(savedLanguage as SupportedLocales) ? (savedLanguage as SupportedLocales) : 'pl'
    localStorage.setItem(STORAGE_LANGUAGE, finalLocale)
    setLocale(finalLocale)
  }, [])

  const handleSetLocale = (newLocale: SupportedLocales) => {
    setLocale(newLocale)
    localStorage.setItem(STORAGE_LANGUAGE, newLocale)
  }

  return (
    <LanguageContext.Provider value={{ locale, setLocale: handleSetLocale }}>
      <IntlProvider messages={flatten(messages[locale])} locale={locale} defaultLocale="pl">
        {children}
      </IntlProvider>
    </LanguageContext.Provider>
  )
}

export const useLanguageContext = () => useContext(LanguageContext)
