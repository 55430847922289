const button = {
  proceed_to_order: 'Proceed to order',
  go_further: 'Go further',
  go_back: 'Go back',
  try_later: 'Try later',
  try_again: 'Try again',
  reload_page: 'Reload page',
  more: 'More',
  less: 'Less',
  select_all: 'Select all',
  confirm: 'Confirm',
  generate_contract: 'Generate contract',
  close: 'Close',
  cancel: 'Cancel',
  restore: 'Restore',
  edit: 'Edit',
  edit_data: 'Edit data',
  edit_representative_data: 'Edit representative data',
  edit_contract_data: 'Edit contract data',
  download_agreement: 'Download agreement',
  download_agreement_template: 'Download agreement template',
  ok: 'OK',
  order_and_pay: 'Order and pay',
  confirm_and_pay: 'Confirm and pay',
  go_to_the_store: 'Go to the store',
  verify: 'Verify',
  accept: 'Accept',
  extending: 'Extending',
  sign_and_go_further: 'Sign the contract and proceed',
  sign_agreement: 'Sign agreement',
  resend_code: 'Resend code',
  change_phone: 'Change phone number',
  add_to_product: 'Add to product',
  here: 'here',
  click_here_to_learn_more: 'Click {link} to learn more',
  details: 'Details',
  see_more: 'See more',
}

export { button }
