import { useContext, useEffect, useMemo } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { useStepLayoutContext } from 'components/layouts'
import { GeneralRoute, OrderStatus, clearStorage } from 'consts'
import { OrderContext } from 'context'

import { useGetCustomerOrder } from '../api/useGetCustomerOrder'

interface OrderData {
  status: OrderStatus | OrderStatus[]
  isUseContextData?: boolean
  shouldGetAdditionalServices: boolean
}

/**
 * The `useOrderData` function is a custom hook that retrieves order data based on the provided status
 * and whether to use context data, and returns the data, loading state, success state, error state,
 * error message, and a function to refetch the order data.
 */
export const useOrderData = ({ status, isUseContextData, shouldGetAdditionalServices }: OrderData) => {
  const { orderId } = useParams()
  const stepLayoutContext = useStepLayoutContext()
  const { setOrder: setContextOrder } = useContext(OrderContext)
  const { orderProducts, setOrderProducts } = stepLayoutContext ?? {}
  const allowedStatuses = useMemo(() => (Array.isArray(status) ? status : [status]), [status])
  const navigate = useNavigate()
  const { data, isLoading, isFetching, isSuccess, isError, error, refetch } = useGetCustomerOrder(
    (!orderProducts || !isUseContextData) && orderId ? orderId : '',
    shouldGetAdditionalServices,
  )

  useEffect(() => {
    if (data?.status === OrderStatus.BOOKED || data?.status === OrderStatus.CANCELED) {
      navigate(`/${GeneralRoute.ORDER}/${data?.externalId}/${GeneralRoute.ORDER_FINISHED_CONFIRMATION}`)
    } else if (isUseContextData && data && data?.status && allowedStatuses.includes(data?.status)) {
      setOrderProducts(data)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status, data])

  useEffect(() => {
    setContextOrder(orderProducts ?? data ?? null)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, orderProducts])

  useEffect(() => {
    if (isUseContextData && isError) {
      clearStorage()
    }
  }, [isUseContextData, isError])

  if (data?.status === OrderStatus.BOOKED || data?.status === OrderStatus.CANCELED) {
    return {
      data: null,
      isLoading: false,
      isSuccess: false,
      isError: false,
      error: null,
      refetchOrderData: () => {},
    }
  }

  if (isUseContextData && orderProducts) {
    return {
      data: orderProducts,
      isLoading: false,
      isSuccess: true,
      isError: false,
      error: null,
      refetchOrderData: refetch,
    }
  }
  if (!isLoading && data && data?.status && !allowedStatuses.includes(data?.status)) {
    return {
      data: null,
      isLoading: false,
      isSuccess: false,
      isError: true,
      error: null,
      refetchOrderData: refetch,
    }
  }

  return {
    data,
    isLoading: isLoading || isFetching,
    isSuccess,
    isError,
    error,
    refetchOrderData: refetch,
  }
}
