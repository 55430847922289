/* eslint-disable @typescript-eslint/quotes */
/* eslint-disable quotes */
const safeup = {
  confirmCancel: 'Are you sure you want to cancel SafeUp+?',
  safeUpDescription: 'SafeUp+ means greater savings and full device protection.',
  cancelWarning:
    "<strong>Canceling</strong> the SafeUp+ package <strong>may significantly increase</strong> repair costs - depending on the equipment and type of damage, even <strong>up to 1000 PLN or more</strong>. With SafeUp+, you'll <strong>reduce expenses</strong> and gain peace of mind.",
  safeUpPlusBenefits: 'SafeUp+ has many benefits',
  contractDuration: 'The contract lasts 12 months',
  peaceOfMind: 'Gain peace of mind for the entire duration of the contract',
  lowerCosts: 'Lower costs',
  avoidHigherRepairCosts: 'Avoid higher costs in case of repairs',
  keepSafeUpPlus: 'Keep SafeUp+',
  cancelSafeUpPlus: 'Switch to SafeUp',
}

export { safeup }
