import { SxProps, Theme } from '@mui/material'

export const htmlContentStyles: SxProps<Theme> = {
  '& ul': {
    m: 0,
    p: 0,
    '& li': {
      listStyleType: 'none',
      ':before': {
        content: '"\\2714"', // Unicode for check mark
        display: 'inline-block',
        marginRight: '8px',
        color: 'success.main',
        fontWeight: 'bold',
        fontSize: '1.6rem',
      },
    },
  },
  '& p': { mt: 0, mb: 0.8 },
}
