import { useIntl } from 'react-intl'

import { Box, Typography, useMediaQuery, useTheme } from '@mui/material'
import { BasicDialog } from '@rent/ui'
import { htmlContentStyles } from 'components/shared/styles'
import { AdditionalService } from 'models'

import { AdditionalServiceUrlLink } from './AdditionalServiceUrlLink'

interface AdditionalServiceContentProps {
  additionalService: AdditionalService
  handleSelectService: (val: string) => void
  onClose: () => void
}
export const AdditionalServiceContent = ({ additionalService, handleSelectService, onClose }: AdditionalServiceContentProps) => {
  const { formatMessage } = useIntl()
  const { breakpoints } = useTheme()
  const matchesMobile = useMediaQuery(breakpoints.down('sm'))

  return (
    <BasicDialog
      open
      maxWidth="sm"
      fullWidth
      title={
        <Typography variant="inherit" mb={-1} sx={{ mx: -1 }} color="#0c256d">
          {additionalService.name}
        </Typography>
      }
      onClose={onClose}
      actions={[
        {
          label: formatMessage({ id: 'button.add_to_product' }),
          onClick: () => handleSelectService(additionalService.id),
          fullWidth: matchesMobile,
          id: 'dontCancelSafeUpPlus-button',
          actionStyle: { mt: { md: -2 } },
          additionalInfo: (
            <Box sx={{ display: 'flex', alignItems: 'baseline', gap: 0.5 }}>
              <Typography variant="h2" color="primary" sx={{ whiteSpace: 'nowrap' }}>
                {additionalService.b2bAmount} {formatMessage({ id: 'PLN' })}{' '}
              </Typography>
              <Typography variant="inherit" component="span" sx={{ fontSize: '1.4rem' }}>
                {formatMessage({ id: 'net' })}/{formatMessage({ id: 'mth' })}
              </Typography>
            </Box>
          ),
        },
      ]}
    >
      <Box sx={{ textAlign: 'left' }}>
        <Typography component="div" dangerouslySetInnerHTML={{ __html: additionalService.content }} sx={htmlContentStyles} />

        {additionalService.url && <AdditionalServiceUrlLink url={additionalService.url} mt={2} />}
      </Box>
    </BasicDialog>
  )
}
