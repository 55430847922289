const payment = {
  autopay: 'AutoPay',
  payment_from_business_account: '{value} - Payment from a business account',
  payment_from_other_than_business_account: '{value} - Payment from a non-business account',
  fast_processing: 'Fastest order processing thanks to automatic verification.',
  not_so_fast_processing: 'Extended processing time up to 3 business days. Additional identity verification will be required.',
  payment_must_be_made_from_a_business_account:
    'Only a transfer from a business account verifies identity; other payment methods require additional verification.',
  debit_note: 'Debit note',
  payment_processing_takes_up_to_several_business_days: '{value} - Processing up to 7 business days',
  debit_note_will_be_sent: 'The debit note will be issued within 24 hours.',
  you_will_be_redirected_to_the_payment_screen: 'You will be redirected to AutoPay shortly to complete the deposit payment.',
}

export { payment }
