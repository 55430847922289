/* eslint-disable @typescript-eslint/quotes */
/* eslint-disable quotes */

const notification = {
  page_not_found: 'Page not found',
  page_forbidden: 'Access denied',
  page_forbidden_content: 'Your permissions do not allow you to view this page.\nIf this is an error, please contact us at {email}',
  page_not_data: 'No data found',
  unexpected_error: 'An unexpected error occurred',
  unexpected_error_try_later: 'An unexpected error occurred. Please try again later.',
  access_denied: 'Unauthorized access',
  data_loading_error: 'Error loading data',
  required_field: 'Required field',
  max_length_small: 'Maximum 100 characters',
  max_length_medium: 'Maximum 200 characters',
  max_length_large: 'Maximum 500 characters',
  email_invalid: 'Invalid email',
  phoneNumber_invalid: 'Invalid phone number',
  phoneNumber_invalid_pl: 'Phone number must have 9 digits',
  nip_invalid: 'Invalid NIP number',
  pesel_invalid: 'Invalid PESEL number',
  identity_card_number_invalid: 'Invalid ID card number',
  invalid_date: 'Invalid date',
  identity_card_valid_from_invalid: 'ID card issue date is incorrect',
  identity_card_valid_to_invalid: 'ID card expiration date is incorrect',
  return_account_number_invalid: 'Invalid bank account number',
  replacement_agreement_number_invalid: 'Invalid agreement number',
  promocode_not_found: 'The provided promo code does not exist',
  promocode_expired: 'The promo code has expired',
  promocode_applied: 'Products covered by promo code {val} have been recalculated',
  super_promocode_applied: 'Promo code {val} has been successfully applied!',

  error: 'Error',
  bad_data: 'Incorrect data',
  dear_customer: 'Dear Customer',
  default_error: 'It looks like an unexpected error occurred while processing your order. Please try again in a moment.',
  default_error_page_reload:
    'It looks like an unexpected error occurred while processing your order. Please try again in a moment or refresh the page.',

  contract_generation_error: 'Unfortunately, an unexpected error occurred while generating your contract. Please try again later.',
  contract_generation_loading: 'Generating contract',
  please_wait_several_sec: 'Please wait, this may take several seconds.',
  bad_customer_data_content:
    'According to our best knowledge, the data you provided is incorrect. Please verify the correctness of the ID series and number, issue and expiration dates, PESEL, and full name. If everything is correct, please contact us at biuro@wesub.pl.',
  default_customer_name_selected_error:
    'It looks like we cannot find your name on the list of authorized representatives for the selected company. If this is an error, please contact us at biuro@wesub.pl.',
  ags_printer_error:
    'Thank you for your interest in our offer. We have received your application and will try to make a decision as soon as possible. Our consultant will contact you.',
  create_order_default_error: 'It looks like an unexpected error occurred while processing your order. Please try again later.',
  data_verification_title: 'Loading contractor data',
  data_verification_content: 'NIP verification in progress, please wait...',
  data_verification_title_couldnt_check:
    'Unfortunately, we were unable to retrieve the necessary data to prepare the offer. Please try to process the order in the store again.',
  data_verification_title_check_error:
    'Unfortunately, we were unable to retrieve the necessary data to prepare the offer. Please try to process the order in the store again.\n\nIf this is an error, please contact us at biuro@wesub.pl.',
  nip_verification_success_title: 'Verification successful',
  nip_verification_success_content: 'Thank you for your patience.\nYou will soon be redirected to the contract generation screen.',
  title: 'Thank you for your patience',
  generic_error: 'Please try again later or refresh the page.\nIf this is an error, please contact us at biuro@wesub.pl.',
  accept_contract_default_error: 'It looks like an unexpected error occurred while processing your order. Please try again later.',
  you_will_be_redirected_to_the_next: 'You will be redirected to the next step shortly',
  data_verification: 'Data verification in progress',
  thank_you_for_your_order: 'Thank you for placing your order',
  order_finished: 'Your order is complete, check out the rest of our assortment.',
  order_not_found: 'Order not found',
  order_not_found_content: 'Unfortunately, we could not find your order. Please generate a new offer.',
  order_cancelled: 'Unfortunately, we could not find your order. Please generate a new offer.',
  processing_debit_note: 'We are now processing your request for an accounting note. We will get back to you via email within 24 hours.',
  processing_extension: 'Everything is ready! Thank you for extending your subscription agreement for the selected product.',
  contact_us_if_any_question: 'If you have any questions, please contact us at {email}',
  we_need_more_time_to_process_your_order: 'Unfortunately, we need more time to verify your contract.\nWe will get back to you via email.',
  order_processing_generic_error:
    'It looks like an unexpected error occurred while processing your order.\nWe need more time to verify your contract.\nWe will get back to you via email.',
  do_not_close_browser_window: 'Please do not close the browser window.',
  downloading_agreement_template: 'Generating contract template',
  downloading_agreement_template_content: 'Once generated, the contract template will be automatically downloaded in PDF format.',
  downloading_agreement_template_error:
    'Unfortunately, an unexpected error occurred while generating the contract template. Please try again later.',

  replacement_agreement_not_possible:
    'The existing agreement number is incorrect, or the contract item is not eligible for replacement.\nYou can still create a new agreement.',
  replacement_agreement_applied_successfully: 'The product from this contract will be replaced upon signing the new agreement',
  replacement_agreement_no_must_be_verified: 'The contract number must be verified',
  agreement_generated_but_not_accepted:
    'It looks like you generated a contract that was not accepted. Please try processing the order in the store again.',
  pdf_loading_error: 'An unexpected error occurred while loading the contract. Please try again later.',
  products_not_available: 'One or more products in your cart are no longer available. Please try processing the order in the store again.',
  read_the_agreement: 'Review the contract',
  payment_success: 'Thank you for your deposit payment.\nWe will proceed with your order once the payment is verified.',
  waiting_for_payment:
    "It looks like you haven't made the deposit payment yet.\nIf you havent received the payment link, please contact us via email or phone.",
  auto_return_to_the_store: 'Returning to the store in: {val} seconds',
  data_loading: 'Loading data',
  verification_code_generation_error: 'Unfortunately, an unexpected error occurred while signing the contract.\nPlease try again later.',
  agreement_signed_title: 'The contract has been successfully signed!',
  agreement_signed_content: 'You will be redirected to the finalization screen shortly.',
  product_not_found: 'Unfortunately, the selected product could not be found.\nIf this is an error, please contact us at biuro@wesub.pl.',
}

export { notification }
