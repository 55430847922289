const general = {
  product_selection: 'Product Selection',
  personal_data: 'Personal Data',
  contract_generation: 'Contract Generation',
  finalization: 'Finalization',
  safe_purchase: 'Safe Purchase',
  summary: 'Summary',
  insurance_basic: 'Basic Protection SafeUp',
  insurance_basic_subtitle: 'Guarantee of safe use of the subscribed product.',
  insurance_extended: 'Extended Protection SafeUp+',
  insurance_extended_subtitle: 'Extended scope of product protection.',
  order_summary: 'Order Summary',
  in_price: 'Included in price',
  pcs: 'pcs.',
  offer_provider: 'Offer provided by',
  your_offer: 'Your Offer',
  your_offer_loan: 'Your Loan Offer',
  products: 'Products',
  rental_period: 'Rental Period',
  subscription_period: 'Subscription Period',
  loan_period: 'Loan Repayment Period',
  commission: 'Commission',
  commissionIncluded: 'Included in loan amount',
  deposit: 'Refundable Deposit',
  net: 'net',
  PLN: 'PLN',
  mth: 'mo',
  months: 'months',
  price: 'Price',
  installment: 'Installment',
  month_rental_price: 'Monthly Rental Price',
  month_subscription_price: 'Monthly Subscription Price',
  month_loan_price: 'Monthly Loan Installment',
  final_loan_price: 'Final Transfer Amount',
  amount: 'Quantity',
  final_price: 'Final Price',
  need_help: 'Need Help?',
  rental_offer: 'Rental Offer',
  subscription_offer: 'Subscription Offer',
  loan_offer: 'Loan Offer',
  rodo_info: 'information clause',
  statute_rentPay: 'RentPay terms and conditions',
  do_you_have_a_promo_code: 'Do you have a promo code?',
  agreement: 'Agreement',
  terms_of_service: 'Terms of Service',
  privacy_policy: 'Privacy Policy',
  wesub: 'WeSub',
  copyright: 'Copyright © WeSub Spółka Akcyjna. {year} All Rights Reserved.',
  offer_preview: 'Offer Preview',
  additional_services: 'Additional services',
  selected: '(Selected {count, plural, =0 {# services} one {# service} other {# services}})',
  services_count: '({count, plural, =0 {# services} one {# service} other {# services}})',
}

export { general }
