import ApiEndpoints from 'api/endpoints'
import { useRequest } from 'hooks'
import { CustomerOrderShowPaymentDto } from 'models/dto'

/**
 * The function `useGetShowPayment` is a custom hook that makes a request to retrieve an info whether blue media payment is available
 */
export const useGetShowPayment = (orderId: string) => {
  return useRequest<CustomerOrderShowPaymentDto>(ApiEndpoints.getShowPayment(orderId), { enabled: !!orderId })
}
